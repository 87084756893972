@charset "utf-8";

@import "minimal-mistakes/skins/default"; // skin
@import "minimal-mistakes"; // main partials

html {
    font-size: 15px; // change to whatever
  
    @include breakpoint($medium) {
      font-size: 17px; // change to whatever
    }
  
    @include breakpoint($large) {
      font-size: 19px; // change to whatever
    }
  
    @include breakpoint($x-large) {
      font-size: 21px; // change to whatever
    }
}